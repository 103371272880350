const bcrypt = require('bcryptjs')
const md5 = require("crypto-js")
const frase= "password"

module.exports =   {
                    encriptarMD5: async (campo) => {
                                           let respuestaf= md5.AES.encrypt(campo,frase)
                                           
                                           return respuestaf
                    },
                    encriptar: async (campo) => { 
                                                  const hash =  await bcrypt.hash(campo, 8) 
                                                 return hash
                    },
                    comparar: async (campo, campobase )=> {  
                                                                var estado = await bcrypt.compare(campo,campobase)                                                    
                                                                return estado
                    }   
}