<template>
    <v-container fill-height>
          <v-card outlined max-width="600"  class="ma-auto justify-center" >
            <v-card-text>
              <v-row class="pa-1 justify-center">   
                                             
                  <v-avatar size="100">
                        <img
                          src="@/assets/logo_redondo.png"
                          alt="Rincon Club"
                        >
                </v-avatar>
              </v-row> 
              <v-row class="mx-4"> 
                <v-form  ref="form" v-on:submit.prevent="login" v-model="validado">
                <v-text-field 
                              required
                              :rules="loginUserRules"
                              v-model="loginuser"                  
                              autofocus                              
                              label="Usuario" 
                              prepend-icon="mdi-account-circle"
                              autocomplete="new-password"
                              placeholder=" " />
                <v-text-field            
                            required                            
                            @keyup.13="login()"
                            :rules="loginPasswordRules"
                            :type="'password'"
                            v-model="loginpassword"
                            label="Password"
                            prepend-icon="mdi-lock"
                            autocomplete="new-password"
                            placeholder=" " />
                  </v-form>
                </v-row>
            </v-card-text>
            <v-divider ></v-divider>
            <v-card-actions  >            
                <v-row>
                  <v-col>
                    <v-btn  :loading="procesando" block :disabled="!validado" color="success" @click=login()>Ingresar</v-btn>
                  </v-col>
                </v-row>   
                <!--<v-row>
                  <v-col >
                        <v-btn > 
                        <v-icon left>mdi-google</v-icon>
                        Login Google
                      </v-btn> 
                      </v-col>
                      <v-col >
                        <v-btn > 
                        <v-icon left>mdi-facebook</v-icon>
                        Login Facebook
                      </v-btn>
                      </v-col>
                  </v-row>-->            
            </v-card-actions>
          </v-card>
           <v-snackbar top dense class="ma-0 pa-0" dark v-model="snackbar" :timeout="timeOutSnack" :color="colorSnack">
                                                {{ textoMensaje}}
                                                <template v-slot:action="{ attrs }">
                                                <v-btn class="ma-0 pa-2"
                                                outlined
                                                small                                                
                                                v-bind="attrs"
                                                @click="snackbar = false"
                                                >
                                                Cerrar
                                                </v-btn>
                                        </template>
                             </v-snackbar>  
    </v-container>  
</template>
<script>
import { mapState } from 'vuex'
import encriptacion from '@/service/encriptacion.js'

export default {
                name:'vista_login',
             // layout:'pruebas',
              data(){
                    return{
                            procesando:false,
                            contador_logins:0,
                            loginUserRules:[
                                        v => !!v || 'El usuario es requerido',                                         
                                        ],
                            loginPasswordRules:[v=>!!v || 'El password es requerido'],
                            validado:true,
                            showPassword: false,
                            loginuser:'',
                            loginpassword:'',
                             snackbar:false,
                            textoMensaje:'',
                            colorSnack:'success',
                            timeOutSnack:6000
              
                          }
                    },
              created(){
                       this.$store.commit('grabarLayout', 'login-layout')
                       //alert(this.$vuetify.breakpoint.name)
              },              
              computed:{
                        ...mapState(['autenticado','headers','layout']) 
              },
              methods: {
                        async login(){  
                                      this.procesando=true 
                                      let password_enc= await encriptacion.encriptarMD5(this.loginpassword)                                              
                                      await this.$http.post(process.env.VUE_APP_API + '/user/login/', {
                                                     user: this.loginuser,
                                                      clave: password_enc.toString()                                                       
                                          })
                                          .then(res =>{
                                                      if (res.status==200){   
                                                        //alert("llego algo")
                                                             this.$store.dispatch('login',{userName: res.data.usuario,userId: res.data.id, token: res.data.token})
                                                            this.$http.defaults.headers.common['authorization'] = this.headers 
                                                           // alert(this.headers)
                                                            this.$router.replace({ path:"/listadoservicios"})
                                                      }
                                                      else{
                                                           alert ('Error de login')
                                                           
                                                      }                                                                                             
                                          })
                                              .catch((error)=>{
                                                      //      console.log(JSON.stringify(error.respuesta))
                                                            this.contador_logins=this.contador_logins+1
                                                            if (error.response.status==401){
                                                           this.notificacion(error.response.data.mensaje,'ERROR') 
                                                   }
                                                           // if (this.contador_logins>1){
                                                           //     router.push({name: })
                                                           // }
                                             //  alert("Error en conexion ")
                                          
                                              })
                                              this.procesando=false
                      },
                       notificacion(mensaje,tipo){
                                    //tipo: ERROR
                                    if (tipo=="ERROR"){
                                                    this.timeOutSnack='-1'
                                                     this.colorSnack='error'
                                                     this.textoMensaje=mensaje
                                                     this.snackbar=true    
                                            
                                    }else {
                                             this.timeOutSnack='4000'
                                             this.colorSnack='success'    
                                             this.textoMensaje=mensaje
                                             this.snackbar=true 

                                    }
                          }
              }            
            }
</script>